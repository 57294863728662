import React from 'react';
import { useNavigate, ScrollRestoration } from 'react-router-dom';
import Navbar from "./Navbar";
import Footer from './Footer';
import 'animate.css';
import ivecodaily35 from '../resources/Ivecodaily35.jpg';
import ivecodaily35A from '../resources/Ivecodaily35t.jpg';
import UNC753 from '../resources/UNC753.jpg';
import MercedesAxor from '../resources/MercedesAxor.jpg';
import MercedesAtego from '../resources/MercedesAtego.jpg';
import FormularDoprava from './FormularDoprava';
import bagerYanmar from '../resources/BagerYANMAR.jpg';
function Doprava() {
    const navigate = useNavigate();

    const handleRoute = (route) => () => {
      navigate(route);
    };
    return (
        <div>
            <Navbar />
            <div className="text-center mt-48 text-5xl font-extrabold mb-16 text-gray-800">
                Naša <span className="text-red-600">ponuka</span>
            </div>
            <div className="flex flex-wrap max-w-full  justify-center m-auto rounded-2xl gap-2 ">

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={ivecodaily35} className="h-full w-full object-cover" alt="ŠTRKOPIESKY" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Iveco 
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                    Iveco daily do 3,5t
                    </div>
                    <div>
                    <div>
                        <p className="mt-4 text-center">
                          
                        </p>
                    </div>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>Preprava do 5 km </td>
                                    <td>  10,08 € </td>
                                    <td>  12,40 € </td>
                                </tr>
                                <tr>
                                    <td>Cena na 1km</td>
                                    <td>  0,93 € </td>
                                    <td>  1,15 € </td>
                                </tr>
                                <tr>
                                    <td>Nákladka+výkladka</td>
                                    <td>  3,90 € </td>
                                    <td>  4,80 € </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={ivecodaily35A} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Iveco
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Iveco daily nad 3,5t
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                           
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>Preprava do 5 km </td>
                                    <td>  10,40 €  </td>
                                    <td>  12,80 € </td>
                                </tr>
                            <tr>
                                    <td>Cena za 1km</td>
                                    <td>1,02 €</td>
                                    <td>1,25 €</td>
                                </tr>
                                <tr>
                                <td>Nákladka+výkladka</td>
                                    <td>4,55 €</td>
                                    <td>5,60 €</td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={MercedesAxor} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Mercedes
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                    Mercedes axor nad 7,5t
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                           
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>Preprava do 5 km </td>
                                    <td>  11,22 € </td>
                                    <td>  13,80 € </td>
                                </tr>
                            <tr>
                                    <td>Cena za 1km</td>
                                    <td>1,50 €</td>
                                    <td>1,85 €</td>
                                </tr>
                                <tr>
                                <td>Nákladka+výkladka</td>
                                    <td>5,00 €</td>
                                    <td>  6,15 € </td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={MercedesAtego} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Mercedes
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Mercedes atego do 3,5t
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>Preprava do 5 km </td>
                                    <td>  10,40 € </td>
                                    <td>  12,80 € </td>
                                </tr>
                            <tr>
                                    <td>Cena za 1km</td>
                                    <td>1,02 €</td>
                                    <td>1,25 €</td>
                                </tr>
                                <tr>
                                <td>Nákladka+výkladka</td>
                                    <td>4,55 €</td>
                                    <td>5,60 €</td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={MercedesAxor} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Mercedes
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Mercedes axor hydraulicka ruka
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                           
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>Cena za 1km</td>
                                    <td>  1,50 € </td>
                                    <td>  1,85 € </td>
                                </tr>
                                <tr>
                                    <td>Nákladka a výkladka</td>
                                    <td>  4,23 € </td>
                                    <td>  5,20 € </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={bagerYanmar} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Bager YANMAR
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                    Bager YANMAR zemné práce

                    </div>
                    <div>
                        <p className="mt-4 text-center">
                           
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za 1h</td>
                                    <td>  23,98 € </td>
                                    <td>  29,50 € </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={bagerYanmar} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Bager YANMAR
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                    Bager YANMAR búracie kladivo
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                          
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za 1h</td>
                                    <td>  26,02 € </td>
                                    <td>  32,00 € </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={UNC753} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        UNC
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        UNC 753
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                        
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za 1h</td>
                                    <td>  26,02 € </td>
                                    <td>  32,00 € </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-300 mt-10 pt-4 w-2/3 m-auto">
            </div>
            <FormularDoprava />
            <Footer />
            <ScrollRestoration />
        </div>
    );
    };
export default Doprava;