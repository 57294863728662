import Navbar from './Navbar';
import Footer from './Footer';
import 'animate.css';
import strkopiesky04 from '../resources/Štrkopiesok 0-4.jpeg';
import strkopiesky016 from '../resources/Štrkopiesok 0-16.jpeg';
import strkopiesky48 from '../resources/Štrkopiesok 4-8.jpeg';
import strkopiesky816 from '../resources/Štrkopiesok 8-16.jpeg';
import Formular from './Formular';
import { useEffect, useState } from 'react';
import { useLocation, ScrollRestoration } from 'react-router-dom';

function Strkopiesky() {

    const [items, setItems] = useState([]);
    const location = useLocation();
    useEffect(() => {
        handleWhatOrder();
    },[location.pathname]);

    const handleWhatOrder = () => {
        if(location.pathname === '/ponuka/strkopiesky'){
            setItems([
                'Frakcia 0/4 Štrkopiesky',
                'Frakcia 4/8 Štrkopiesky',
                'Frakcia 0/16 Štrkopiesky',
                'Frakcia 8/16 Štrkopiesky'
            ]);
        }
    };
    return (
        <div>
            <Navbar />
            <div className="text-center mt-48 text-5xl font-extrabold mb-16 text-gray-800">
                Naša <span className="text-red-600">ponuka</span>
            </div>
            <div className="flex flex-wrap max-w-full  justify-center  m-auto rounded-2xl gap-2 ">
              
                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky04} className="h-full w-full object-cover" alt="ŠTRKOPIESKY" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                            Ťažené riečne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 0/4
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                        Vhodný ako podkladová vrstva pod exteriérovú dlažbu, zhutňujúca vrstva, na obsypanie vodovodných a kanalizačných potrubí.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>19,02 €</td>
                                    <td>23,40 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              
                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky48} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Ťažené riečne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 4/8
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                        Triedený, praný štrk vhodný ako dekoratívny, vhodný ako prímes pre špeciálne betóny, obsypy drenáže.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>19,27 €</td>
                                    <td>23,70 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky016} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Ťažené riečne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 0/16
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                        Betonársky štrk - vhodný na všeobecnú betonáž základov, prekladov, stropných dosiek, výrobu betónových výrobkov.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>19,02 €</td>
                                    <td>23,40 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={strkopiesky816} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Ťažené riečne kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 8/16
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Dekoratívny triedený a praný štrk, vhodný na voľné plochy, taktiež vhodný ako zásyp na ploché strechy, zásypy drenáži a základov.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>19,84 €</td>
                                    <td>24,40 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-300 mt-10 pt-4 w-2/3 m-auto">
            </div>
            <Formular napln={items} />
            <Footer />
            <ScrollRestoration />
        </div>
    );
}

export default Strkopiesky;
