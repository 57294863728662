import Navbar from './Navbar';
import Footer from './Footer';
import 'animate.css';
import piesok01 from '../resources/Piesok 0-1.jpeg';
import piesok02 from '../resources/Piesok 0-2.jpeg';
import Formular from './Formular';
import { useEffect, useState } from 'react';
import { useLocation, ScrollRestoration } from 'react-router-dom';
function Piesok() {

    const [items, setItems] = useState([]);
    const location = useLocation();
    useEffect(() => {
        handleWhatOrder();
    },[location.pathname]);

    const handleWhatOrder = () => {
        if(location.pathname === '/ponuka/piesok'){
            setItems([
                'Frakcia 0/1 Piesok',
                'Frakcia 0/2 Piesok'
            ]);
        }
    };
    return (
        <div>
            <Navbar />
            <div className="text-center mt-48 text-5xl font-extrabold mb-16 text-gray-800">
                Naša <span className="text-red-600">ponuka</span>
            </div>
            <div className="flex flex-wrap justify-center max-w-full w-11/12 m-auto rounded-2xl space-x-4">
                <div className="relative p-8 w-full sm:w-1/2 md:w-1/3 shadow mb-4">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={piesok01} className="h-full w-full object-cover" alt="Piesok Frakcia 0/1" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                            Piesok
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 0/1
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný na betón, murovacie malty, omietky a špárovanie zámkovej dlažby.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>  19,76 € </td>
                                    <td>  24,30 € </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

              
                <div className="relative p-8 w-full sm:w-1/2 md:w-1/3 shadow mb-4">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={piesok02} className="h-full w-full object-cover" alt="Piesok Frakcia 0/2" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                            Piesok
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 0/2
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný na betón, murovacie malty, omietky a špárovanie zámkovej dlažby.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>  19,02 € </td>
                                    <td>  23,40 € </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="border-t border-gray-300 mt-10 pt-4 w-2/3 m-auto"></div>
            <Formular napln={items}/>
            <Footer />
            <ScrollRestoration />
        </div>
    );
}

export default Piesok;
